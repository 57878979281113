import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {graphql} from "gatsby"
import {useTranslation} from "react-i18next"
import {references} from "../components/references"

const seoData = {
    title: "ONAYLARIM",
    description: "Referanslarımız",
    slug: ""
}

const ReferencesPage = () => {
    const {t} = useTranslation()
    return (
        <Layout pageTitle="Referanslar">
            <Seo title={seoData.title} description={seoData.description || seoData.excerpt || "nothin’"} pathname={seoData.slug} article />
            <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{t("references_title")}</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">{t("references_description")}</p>
                </div>

                <div className="bg-white py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="-mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-4">
                            {references.map((reference) => {
                                return (
                                    <div className="p-6 sm:p-10 flex flex-col items-center justify-center">
                                        <img key={reference.name} alt={reference.name} src={reference.logo} className="h-48 w-48 object-contain" />
                                        <h2 className="mt-2 font-medium">{reference.name}</h2>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ReferencesPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
